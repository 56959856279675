import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const EditCollection = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [collectionDetails, setCollectionDetails] = useState({ name: '', description: '', coverImage: '', additionalImages: [] });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCollectionDetails = async () => {
      try {
        const response = await fetch(`https://fash-backend-59z5.onrender.com/item/collection/${id}`);
        const data = await response.json();
        setCollectionDetails({
          name: data.name,
          description: data.des,
          coverImage: data.img_Url || '',
          additionalImages: data.img_Arr_Url || [],
        });
      } catch (error) {
        console.error('Error fetching collection details:', error);
      }
    };

    fetchCollectionDetails();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCollectionDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setCollectionDetails((prev) => ({ ...prev, coverImage: reader.result }));
      reader.readAsDataURL(file);
    }
  };

  // const handleAdditionalImagesChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   const urls = files.map((file) => URL.createObjectURL(file));
  //   setCollectionDetails((prev) => ({ ...prev, additionalImages: urls }));
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess('');
    setError('');

    try {
      const response = await fetch(`https://fash-backend-59z5.onrender.com/item/collection/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: collectionDetails.name,
          des: collectionDetails.description,
          img_Url: collectionDetails.coverImage,
          img_Arr_Url: collectionDetails.additionalImages,
        }),
      });

      if (response.ok) {
        setSuccess('Collection updated successfully!');
        setTimeout(() => navigate('/admin/collection'), 1500); // Redirect after update
      } else {
        setError('Failed to update collection.');
      }
    } catch (error) {
      console.error('Error updating collection:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-8 max-w-screen-3xl mx-auto">
      {/* Back and Add New Collection Header */}
      <div className="flex justify-between items-center mb-6">
        <button className="text-black flex items-center" onClick={() => window.history.back()}>
          &larr; Back to Inventory
        </button>
        <h1 className="text-2xl font-bold">Edit Collection</h1>
      </div>

      {/* Success or Error Message */}
      {success && <div className="text-green-500 mb-4">{success}</div>}
      {error && <div className="text-red-500 mb-4">{error}</div>}

      {/* Main Layout with Form */}
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Side - Collection Details */}
          <div>
            <div className="mb-6">
              <label className="block font-semibold">Collection Name</label>
              <input
                type="text"
                name="name"
                value={collectionDetails.name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter Collection Name"
              />
            </div>

            <div className="mb-6">
              <label className="block font-semibold">Collection Description</label>
              <textarea
                name="description"
                value={collectionDetails.description}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter Collection Description"
                rows="5"
              />
            </div>
          </div>

          {/* Right Side - Collection Images */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Upload Collection Images</h3>
            <p className="mb-4">Please upload Collection images to complete your listing and showcase your item to potential buyers.</p>

            <div className="grid grid-cols-2 gap-4">
              <div className="border p-4 rounded-lg h-36 flex items-center justify-center">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleCoverImageChange}
                  className="hidden"
                  id="coverImageUpload"
                />
                <label htmlFor="coverImageUpload" className="cursor-pointer bg-black text-white py-2 px-4 rounded-md">
                  Select Cover Image
                </label>
              </div>
              {/* <div className="border p-4 rounded-lg h-36 flex items-center justify-center">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleAdditionalImagesChange}
                  multiple
                  className="hidden"
                  id="additionalImagesUpload"
                />
                <label htmlFor="additionalImagesUpload" className="cursor-pointer bg-black text-white py-2 px-4 rounded-md">
                  Add Image URLs
                </label>
              </div> */}
            </div>

            {collectionDetails.coverImage && (
              <div className="mt-4">
                <h4 className="font-semibold">Cover Image:</h4>
                <img src={collectionDetails.coverImage} alt="Cover" className="mt-2 max-w-full h-auto" />
              </div>
            )}

            {/* {collectionDetails.additionalImages.length > 0 && (
              <div className="mt-4">
                <h4 className="font-semibold">Additional Images:</h4>
                <div className="flex flex-wrap mt-2">
                  {collectionDetails.additionalImages.map((url, index) => (
                    <img key={index} src={url} alt={`Additional ${index + 1}`} className="w-24 h-24 object-cover mr-2 mb-2" />
                  ))}
                </div>
              </div>
            )} */}
          </div>
        </div>

        <button
          type="submit"
          className="mt-8 w-full lg:w-1/2 bg-black text-white py-3 rounded-md font-semibold"
          disabled={loading}
        >
          {loading ? 'Updating Collection...' : 'Update Collection'}
        </button>
      </form>
    </div>
  );
};

export default EditCollection;
