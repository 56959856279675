import React from 'react'
import HomeProduct from './HomeProducts'
import HomeHero from './HomeHero'
import HomeImages from './HomeImages'

const HomePage = () => {
  return (
    <><HomeHero /><HomeProduct /><HomeImages /></>
  )
}

export default HomePage
