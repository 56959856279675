import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";


const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h1 className="text-3xl text-center mb-7 tracking-widest">
         {t('origins.title')}
      </h1>
      <p className="text-lg mb-6 text-center ">
      {t('origins.description')}
      </p>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 lg:mb-10">
        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1731328561/pexels-kelly-1179532-17290985_njfcyc.webp"
            alt="Fashion Look 1"
            width={300}
            height={300}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>

           <p className="text-justify leading-7 mt-6 mb-6 max-w-5xl mx-auto">

           {t('origins.first_text')}
        
        <br /><br />
        
        {t('origins.second_text')}    <br /><br />
        
        {t('origins.third_text')}
      </p>

      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 lg:mb-10">
        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1730892216/must_use_1_nka3oj.webp"
            alt="Fashion Look 1"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>

            <p className="text-justify leading-7 mt-6 mb-6 max-w-5xl mx-auto">

            {t('origins.fourth_text')}
        
        <br /> <br />
        
        {t('origins.fifth_text')}
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:mb-10">
        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1730890488/pexels-anassfakhari-1458770_gpfcsu.webp"
            alt="Fashion Look 1"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>

        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1730890488/must_use_wear_taking_about_collection_xb4zse.webp"
            alt="Fashion Look 2"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>

            <p className="text-justify leading-7 mt-6 mb-6 max-w-5xl mx-auto">

            {t('origins.sixth_text')}
        
        <br /> <br />
        
        {t('origins.seventh_text')}     <br /> <br />
        
        {t('origins.eighth_text')}
      </p>

      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 lg:mb-10">
        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1731339388/hgvkcjsbdvhkds_pnvxim.webp"
            alt="Fashion Look 1"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>

           <p className="text-justify leading-7 mt-6 mb-6 max-w-5xl mx-auto">

           {t('origins.ninth_text')} 
        
        <br /><br />
        
        {t('origins.tenth_text')}    <br /><br />

        {t('origins.eleventh_text')}
      </p>

      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 lg:mb-10">
        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1730890489/must_use_talking_about_colection_2_gibzrb.webp"
            alt="Fashion Look 1"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>

      <p className="text-justify leading-7 mt-6 max-w-5xl mx-auto">

           {t('origins.twelfth_text')}
        
        <br /> <br />
        
        {t('origins.thirteenth_text')}
        <br /> <br />
        
        
        {t('origins.fourteenth_text')}      <br /> <br />
        
        {t('origins.fifteen_text')}    <br /> <br />
        
        {t('origins.sixteen_text')}    <br /> <br />
        
        
        {t('origins.seventeen_text')}

        <br /> <br />
        
        {t('origins.eighteen_text')}
        
        <br /> <br />
        
        {t('origins.nineteen_text')}
        <br /> <br />
        
        {t('origins.twenty_text')}  <br /> <br />
        
        
        {t('origins.twentyone_text')}
      </p>
    </div>
  );
};

export default AboutUs;
