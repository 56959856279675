import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function PaymentSuccessContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const transactionRef = new URLSearchParams(location.search).get('reference');

  // States to store payment details
  const [orderNumber, setOrderNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  console.log(isVerified)

  useEffect(() => {
    const verifyPayment = async () => {
      if (transactionRef) {
        try {
          const response = await fetch(`/api/verify-payment?reference=${transactionRef}`);
          const result = await response.json();

          if (result.status === 'success') {
            // Set the payment details in state
            setOrderNumber(result.orderNumber || 'N/A');
            setAmount(result.amount || 'N/A');
            setTransactionId(result.transactionId || transactionRef);
            setIsVerified(true);
          } else {
            console.error('Payment verification failed:', result);
            navigate('/failed-payment'); // Redirect if verification fails
          }
        } catch (error) {
          console.error('Error verifying payment:', error);
        }
      } else {
        console.error('Transaction reference not found.');
      }
    };

    verifyPayment();
  }, [transactionRef, navigate]);

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-50">
      <div className="bg-white shadow-md rounded-lg p-8 w-full max-w-md">
      
            <div className="flex justify-center items-center mb-6">
              <div className="bg-green-200 p-2 rounded-full">
                <svg className="h-8 w-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
            </div>
            <h1 className="text-2xl font-bold text-center mb-4">PAYMENT SUCCESSFUL</h1>
            <p className="text-center mb-6 text-gray-600">
              Thank you! Your order has been processed successfully. You will receive a confirmation email with the order details.
            </p>
            <div className="space-y-2 text-sm text-gray-700">
              <div className="flex justify-between">
                <span>Order Number</span>
                <span>{orderNumber}</span>
              </div>
              <div className="flex justify-between">
                <span>Amount</span>
                <span>${amount}</span>
              </div>
              <div className="flex justify-between">
                <span>Transaction ID</span>
                <span>{transactionId}</span>
              </div>
            </div>
            <button
              className="mt-8 w-full bg-black text-white py-2 hover:bg-gray-800 transition duration-300"
              onClick={() => navigate('/')}
            >
              Continue Shopping
            </button>
          
        
      </div>
    </div>
  );
}

export default function SuccessPayment() {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <PaymentSuccessContent />
    </React.Suspense>
  );
}
