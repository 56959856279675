import { useCart } from '../../Context/CartContext';
import { useNavigate } from 'react-router-dom';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AiOutlineShopping,
} from 'react-icons/ai';

export default function Bag() {
  const { t } = useTranslation();
  const { cart, cartCount, updateCartItem, removeFromCart, totalPrice } = useCart();
  const navigate = useNavigate();

  const cartItems = useMemo(() => (
    cart.map(item => ({
      ...item,
      totalPrice: (item.price * item.quantity).toFixed(2)
    }))
  ), [cart]);

  const handleCheckout = useCallback(() => {
    navigate('/checkout');
  }, [navigate]);

  return (
    <div className="container mx-auto py-10 px-3">
      {cartCount === 0 ? (
        <div className="flex flex-col items-center justify-center h-64">    <AiOutlineShopping className="text-7xl mb-5" />
          <p className="text-lg font-medium">{t('cart.empty_cart_message')}</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Section: Cart Items */}
          <div className="lg:col-span-2">
          <h1 className="text-2xl font-bold mb-6 tracking-widest">
  {t('cart.shopping_bag_items')}
</h1>            {cartItems.map((item) => (
              <div key={`${item.id}-${item.color}-${item.size}`} className="flex justify-between items-start border-b pb-6 mb-6 space-x-6">
                <div className="w-24 h-24 bg-gray-100">
                  <img src={item.image} alt={item.name} className="rounded w-full h-full object-cover" />
                </div>

                <div className="flex-1 space-y-2">
                  <h2 className="font-semibold text-md tracking-wider">{item.name}</h2>
                  <p className="text-xs">{t('men.color')}: {item.color}</p>
                  <p className="text-xs">{t('men.size')}: {item.size}</p>

                  <div className="flex items-center space-x-2 mt-2">
                    <button
                      onClick={() => updateCartItem(item.id, item.color, item.size, Math.max(item.quantity - 1, 0))}
                      className="px-2 py-0 border rounded text-gray-600 hover:bg-gray-200"
                    >
                      -
                    </button>
                    <span>{item.quantity}</span>
                    <button
                      onClick={() => updateCartItem(item.id, item.color, item.size, item.quantity + 1)}
                      className="px-2 py-0 border rounded text-gray-600 hover:bg-gray-200"
                    >
                      +
                    </button>
                  </div>
                  <button
                    onClick={() => removeFromCart(item.id, item.color, item.size)}
                    className="text-xs text-red-500 hover:underline"
                  >
                  {t('cart.remove_button')}
                  </button>
                </div>

                {/* Price */}
                <div className="text-right">
                  <p className="font-bold text-sm">${item.totalPrice}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Right Section: Order Summary */}
          <div className="p-6 bg-gray-50">
  <div className="flex justify-between items-center mb-6">
    <h1 className="text-md font-bold tracking-widest">{t('order_summary.order_summary')}</h1>
    <p className="font-semibold">{t('order_summary.items')} ({cartCount})</p>
  </div>
  <div className="space-y-8">
    <div className="flex justify-between text-sm">
      <p>{t('order_summary.subtotal')}</p>
      <p>${totalPrice.toFixed(2)} USD</p>
    </div>
    <div className="flex justify-between text-sm">
      <p>{t('order_summary.delivery')}</p>
      <p className="text-green-600">{t('order_summary.free')}</p>
    </div>
    <hr className="border-t border-gray-300 my-4" />
    <div className="flex justify-between font-bold text-md">
      <p>{t('order_summary.total')}</p>
      <p>${totalPrice.toFixed(2)}</p>
    </div>
    <button
      className="w-full bg-black text-white py-3 mt-4 hover:bg-gray-800 tracking-widest"
      onClick={handleCheckout}
    >
      {t('order_summary.checkout')}
    </button>
  </div>
</div>

        </div>
      )}
    </div>
  );
}
