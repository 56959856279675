import React from "react";
import FaqItem from "./FaqItem";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-2xl mx-auto px-4 py-8">
      <h1 className="text-lg lg:text-3xl text-center mb-10 tracking-widest font-bold">
        {t("faq.title")}
      </h1>
      <div>
        {t("faq.items", { returnObjects: true }).map((faq, index) => (
          <FaqItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default Faq;
