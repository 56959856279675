import React from 'react';
import TotalOrders from '../components/TotalOrders';
import TopProducts from '../components/TopProducts';
import Revenue from '../components/Revenue';
import RecentOrders from '../components/RecentOrders';
import ProductInventory from '../components/ProductInventory'

const Dashboard = () => {
  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <TotalOrders />
        <TopProducts />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
        <Revenue />
        <RecentOrders />
        <ProductInventory />
      </div>
    </div>
  );
};

export default Dashboard;
