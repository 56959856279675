import React, { useState, useEffect } from 'react';

const AddProducts = () => {
  const [productDetails, setProductDetails] = useState({
    name: '',
    price: '',
    category: '',
    sex: '',
    quantity: '',
    collection: '',
    status: 'Active',
    sizes: [],
    colors: [],
    details: '',
    description: '',
    img_Url: '',
    hover_Url: '',
    img_Arr_Url: [],
    featured: false,
    isArchived: false
  });

  const [collections, setCollections] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [hoverImage, setHoverImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);

  // Fetch collections and categories when component mounts
  useEffect(() => {
    const fetchCollectionsAndCategories = async () => {
      try {
        const collectionResponse = await fetch('https://fash-backend-59z5.onrender.com/item/collection');
        const categoryResponse = await fetch('https://fash-backend-59z5.onrender.com/item/category');
        
        const collectionsData = await collectionResponse.json();
        const categoriesData = await categoryResponse.json();

        if (collectionResponse.ok) {
          setCollections(collectionsData); // Assuming response is an array of collections
        } else {
          setError('Failed to fetch collections');
        }

        if (categoriesData.status) {
          setCategories(categoriesData.data.uniqueCategories); // Assuming this is the correct structure
        } else {
          setError('Failed to fetch categories');
        }
      } catch (err) {
        setError('Failed to fetch collections or categories');
      }
    };

    fetchCollectionsAndCategories();
  }, []);

// Inside the AddProducts component
const handleInputChange = (e) => {
  const { name, value, type, checked } = e.target;
  setProductDetails((prev) => ({
    ...prev,
    [name]: type === "checkbox" ? checked : value,
  }));
};
  // Handle sizes and colors change
  const handleSizesChange = (size) => {
    setProductDetails((prev) => ({
      ...prev,
      sizes: prev.sizes.includes(size)
        ? prev.sizes.filter((s) => s !== size)
        : [...prev.sizes, size],
    }));
  };

  const handleColorsChange = (color) => {
    setProductDetails((prev) => ({
      ...prev,
      colors: prev.colors.includes(color)
        ? prev.colors.filter((c) => c !== color)
        : [...prev.colors, color],
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    const requestBody = {
      name: productDetails.name,
      sex: productDetails.sex,
      size: productDetails.sizes,
      color: productDetails.colors,
      price: parseFloat(productDetails.price),
      quantity: productDetails.quantity,
      _collection: productDetails.collection,
      category: productDetails.category,
      img_Url: productDetails.img_Url,
      hover_Url: productDetails.hover_Url,
      img_Arr_Url: productDetails.img_Arr_Url,
      featured: productDetails.featured,
      isArchived: productDetails.isArchived,
      detail: productDetails.details,
      description: productDetails.description,
    };

    try {
      const response = await fetch('https://fash-backend-59z5.onrender.com/item', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Something went wrong. Please try again.');
      }

      const result = await response.json();
      console.log('API Response:', result);
      setSuccess('Product added successfully!');
      setProductDetails({
        name: '',
        price: '',
        category: '',
        quantity: '',
        sex: '',
        collection: '',
        sizes: [],
        colors: [],
        details: '',
        description: '',
        img_Url: '',
        hover_Url: '',
        img_Arr_Url: [],
        featured: false,
        isArchived: false,
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle cover image upload
  const handleCoverImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = await uploadImage(file);
      setCoverImage(url);
      setProductDetails((prev) => ({ ...prev, img_Url: url }));
    }
  };

  const handleHoverImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = await uploadImage(file);
      setHoverImage(url);
      setProductDetails((prev) => ({ ...prev, hover_Url: url }));
    }
  };

  // Handle additional images upload
  const handleAdditionalImagesChange = async (event) => {
    const files = Array.from(event.target.files);
    const urls = await Promise.all(files.map(file => uploadImage(file)));
  
    // Append new images to the existing array
    setAdditionalImages((prevImages) => [...prevImages, ...urls]);
    setProductDetails((prev) => ({
      ...prev,
      img_Arr_Url: [...prev.img_Arr_Url, ...urls],
    }));
  };
  

  // Upload image to Cloudinary
  const uploadImage = async (file) => {
    const cloudName = 'deofmlywm'; // Replace with your Cloudinary cloud name
    const uploadPreset = 'my_upload_preset'; // Replace with your upload preset

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);

    const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();
    return data.secure_url; // Returns the URL of the uploaded image
  };

  const handleRemoveImage = (indexToRemove) => {
    setAdditionalImages((prevImages) => prevImages.filter((_, index) => index !== indexToRemove));
    setProductDetails((prev) => ({
      ...prev,
      img_Arr_Url: prev.img_Arr_Url.filter((_, index) => index !== indexToRemove),
    }));
  };

  const handleRemoveCoverImage = () => {
    setCoverImage(null);
    setProductDetails((prev) => ({ ...prev, img_Url: '' }));
  };
  
  const handleRemoveHoverImage = () => {
    setHoverImage(null);
    setProductDetails((prev) => ({ ...prev, hover_Url: '' }));
  };
  

  
  return (
    <div className="p-8 max-w-screen-3xl mx-auto">
      {/* Back and Add New Product Header */}
      <div className="flex justify-between items-center mb-6">
        <button className="text-black flex items-center" onClick={() => window.history.back()}>
          &larr; Back to Product
        </button>
        <h1 className="text-2xl font-bold">Add New Product</h1>
      </div>

      {/* Success or Error Message */}
      {success && <div className="text-green-500 mb-4">{success}</div>}
      {error && <div className="text-red-500 mb-4">{error}</div>}

      {/* Main Layout with Form */}
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Side - Product Details */}
          <div>
            <div className="mb-6">
              <label className="block font-semibold">Product Name</label>
              <input
                type="text"
                name="name"
                value={productDetails.name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter name"
              />
            </div>

            <div className="mb-6">
              <label className="block font-semibold">Product Price</label>
              <input
                type="text"
                name="price"
                value={productDetails.price}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter price"
              />
            </div>

            <div className="mb-6">
              <label className="block font-semibold">Product Quantity</label>
              <input
                type="text"
                name="quantity"
                value={productDetails.quantity}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter Quantity"
              />
            </div>

            <div className="mb-6">
              <label className="block font-semibold">Product Category</label>
              <select
                name="category"
                value={productDetails.category}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                required
              >
                <option value="" disabled>Select category</option>
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-6">
  <label className="block font-semibold">Collection</label>
  <select
    name="collection"
    value={productDetails.collection}
    onChange={handleInputChange}
    className="w-full p-2 border rounded-md"
  >
    <option value="" disabled>Select collection</option> {/* Adding a default option */}
    {collections.map((collection) => (
      <option key={collection.name} value={collection.name}>
        {collection.name}
      </option>
    ))}
  </select>
</div>

<div className="mb-6">
          <label className="block font-semibold">Featured</label>
          <select
            name="featured"
            value={productDetails.featured}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-md"
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>

        {/* Is Archived Field */}
        <div className="mb-6">
          <label className="block font-semibold">Is Archived</label>
          <select
            name="isArchived"
            value={productDetails.isArchived}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-md"
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>



            <div className="mb-6">
              <label className="block font-semibold">Sex</label>
              <select
                name="sex"
                value={productDetails.sex}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
              >
                <option value="">Select sex</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>

            <div className="mb-6">
              <label className="block font-semibold">Sizes</label>
              <div className="grid grid-cols-6 gap-2">
                {['XS', 'S', 'M', 'L', 'XL', 'XXL'].map((size) => (
                  <label key={size} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={productDetails.sizes.includes(size)}
                      onChange={() => handleSizesChange(size)}
                    />
                    <span className="ml-2">{size}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="mb-6">
              <label className="block font-semibold">Colors</label>
              <div className="flex flex-wrap gap-2">
                {['Beige', 'Brown', 'Cream', 'Grey', 'Black', 'White'].map(
                  (color) => (
                    <button
                      key={color}
                      type="button"
                      className={`p-2 border rounded-md ${
                        productDetails.colors.includes(color) ? 'bg-gray-300' : 'bg-white'
                      }`}
                      onClick={() => handleColorsChange(color)}
                    >
                      {color}
                    </button>
                  )
                )}
              </div>
            </div>

            {/* <div className="mb-6">
              <label className="block font-semibold">Product Detail</label>
              <textarea
                name="details"
                value={productDetails.details}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter Product Details"
                rows="5"
              />
            </div> */}

            <div className="mb-6">
              <label className="block font-semibold">Product Description</label>
              <textarea
                name="description"
                value={productDetails.description}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter Product Description"
                rows="5"
              />
            </div>
          </div>

            {/* Right Side - Product Images */}
            <div>
              <h3 className="text-lg font-semibold mb-4">Upload Product Images</h3>
              <p className="mb-4">Please upload product images to complete your listing and showcase your item to potential buyers.</p>

              <div className="grid grid-cols-2 gap-4">
                <div className="border p-4 rounded-lg h-36 flex items-center justify-center">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleCoverImageChange}
                    className="hidden"
                    id="coverImageUpload"
                  />
                  <label htmlFor="coverImageUpload" className="cursor-pointer bg-black text-white py-2 px-4 rounded-md">
                    Select Cover Image
                  </label>
                </div>
                <div className="border p-4 rounded-lg h-36 flex items-center justify-center">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleHoverImageChange}
                    className="hidden"
                    id="hoverImageUpload"
                  />
                  <label htmlFor="hoverImageUpload" className="cursor-pointer bg-black text-white py-2 px-4 rounded-md">
                    Select Hover Image
                  </label>
                </div>
                <div className="border p-4 rounded-lg h-36 flex items-center justify-center">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleAdditionalImagesChange}
                    multiple
                    className="hidden"
                    id="additionalImagesUpload"
                  />
                  <label htmlFor="additionalImagesUpload" className="cursor-pointer bg-black text-white py-2 px-4 rounded-md">
                    Add Product Images
                  </label>
                </div>
              </div>
              {coverImage && (
  <div className="relative mt-4">
    <h4 className="font-semibold">Cover Image:</h4>
    <img src={coverImage} alt="Cover" className="mt-2 max-w-full h-auto rounded-md" />
    <button
      onClick={handleRemoveCoverImage}
      className="absolute top-1 right-1 bg-red-600 text-white rounded-full p-1 text-xs focus:outline-none"
      aria-label="Remove cover image"
    >
      X
    </button>
  </div>
)}

{hoverImage && (
  <div className="relative mt-4">
    <h4 className="font-semibold">Hover Image:</h4>
    <img src={hoverImage} alt="Hover" className="mt-2 max-w-full h-auto rounded-md" />
    <button
      onClick={handleRemoveHoverImage}
      className="absolute top-1 right-1 bg-red-600 text-white rounded-full p-1 text-xs focus:outline-none"
      aria-label="Remove hover image"
    >
      X
    </button>
  </div>
)}


{additionalImages.length > 0 && (
  <div className="mt-4">
    <h4 className="font-semibold">Additional Images:</h4>
    <div className="flex flex-wrap mt-2">
      {additionalImages.map((url, index) => (
        <div key={index} className="relative w-24 h-24 mr-2 mb-2">
          <img src={url} alt={`Additional ${index + 1}`} className="w-full h-full object-cover rounded-md" />
          <button
            onClick={() => handleRemoveImage(index)}
            className="absolute top-1 right-1 bg-red-600 text-white rounded-full p-1 text-xs focus:outline-none"
            aria-label="Remove image"
          >
            X
          </button>
        </div>
      ))}
    </div>
  </div>
)}

            </div>
          
          
        </div>

        <button
          type="submit"
          className="mt-8 w-full lg:w-1/2 bg-black text-white py-3 rounded-md font-semibold"
          disabled={loading}
        >
          {loading ? 'Adding Product...' : 'Add Product'}
        </button>
      </form>
    </div>
  );
};

export default AddProducts;
