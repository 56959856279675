export const sizeGuideData = [
  {
    category: "size.men_shirt",
    sizes: [
      { size: "S", usSize: "36", euSize: "46", chest: "34-36 inches", waist: "28-30 inches" },
      { size: "M", usSize: "38", euSize: "48", chest: "38-40 inches", waist: "32-34 inches" },
      { size: "L", usSize: "40", euSize: "50", chest: "42-44 inches", waist: "36-38 inches" },
      { size: "XL", usSize: "42", euSize: "52", chest: "46-48 inches", waist: "40-42 inches" },
    ],
  },
  {
    category: "size.women_shirt",
    sizes: [
      { size: "S", usSize: "4", euSize: "36", chest: "32-34 inches", waist: "26-28 inches" },
      { size: "M", usSize: "6", euSize: "38", chest: "36-38 inches", waist: "30-32 inches" },
      { size: "L", usSize: "8", euSize: "40", chest: "40-42 inches", waist: "34-36 inches" },
      { size: "XL", usSize: "10", euSize: "42", chest: "44-46 inches", waist: "38-40 inches" },
    ],
  },
  {
    category: "size.men_trousers",
    sizes: [
      { size: "S", usSize: "28", euSize: "44", waist: "28-30 inches", length: "32 inches" },
      { size: "M", usSize: "32", euSize: "46", waist: "32-34 inches", length: "33 inches" },
      { size: "L", usSize: "36", euSize: "48", waist: "36-38 inches", length: "34 inches" },
      { size: "XL", usSize: "40", euSize: "50", waist: "40-42 inches", length: "35 inches" },
    ],
  },
  {
    category: "size.women_trousers",
    sizes: [
      { size: "S", usSize: "4", euSize: "34", waist: "26-28 inches", length: "30 inches" },
      { size: "M", usSize: "6", euSize: "36", waist: "30-32 inches", length: "31 inches" },
      { size: "L", usSize: "8", euSize: "38", waist: "34-36 inches", length: "32 inches" },
      { size: "XL", usSize: "10", euSize: "40", waist: "38-40 inches", length: "33 inches" },
    ],
  },
  {
    category: "size.men_slide",
    sizes: [
      { size: "7", usSize: "8", euSize: "41", footLength: "9.6 inches" },
      { size: "8", usSize: "9", euSize: "42", footLength: "9.9 inches" },
      { size: "9", usSize: "10", euSize: "43", footLength: "10.2 inches" },
      { size: "10", usSize: "11", euSize: "44", footLength: "10.5 inches" },
    ],
  },
  {
    category: "size.women_slide",
    sizes: [
      { size: "6", usSize: "7", euSize: "38", footLength: "9 inches" },
      { size: "7", usSize: "8", euSize: "39", footLength: "9.3 inches" },
      { size: "8", usSize: "9", euSize: "40", footLength: "9.6 inches" },
      { size: "9", usSize: "10", euSize: "41", footLength: "9.9 inches" },
    ],
  },
];
