import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import axios from 'axios'; // Import axios for making HTTP requests

const Login = () => {
  const [email, setEmail] = useState(''); // State to hold email
  const navigate = useNavigate(); // Initialize useNavigate
  const [error, setError] = useState(''); // This is where you store your error message


  
  const handleGenerateCode = async () => {
    try {
      const { data } = await axios.post('https://fash-backend-59z5.onrender.com/au/trigger', { email });

      if (data.status) {  // Check if status is truthy (works for both true and 'true')
        localStorage.setItem("adminEmail", email);
        navigate('/admin/otp-login');
      } else {
        throw new Error(data.message || 'Email not found');
      }
    } catch (error) {
      console.error('Error generating OTP:', error);
      // Check if error.response exists to handle HTTP errors
      const errorMessage = error.response?.data?.message || 'Failed to send OTP. Please check your email address.';
      setError(errorMessage); // Update the error state
    }
  };

  return (
    <div className="flex justify-center items-center bg-gray-50">
      <div className="rounded-xl shadow-xl w-full">
        
        {/* Admin Sign In Header */}
        <h2 className="text-3xl font-bold text-center mb-6">Admin Sign in</h2>
        
        {/* Description */}
        <p className="text-gray-500 text-center mb-8">
          Log in to the Fash dashboard with your credentials to manage operations.
        </p>

        {error && <div className="error-message">{error}</div>} {/* Display the error message */}

        
        {/* Input Field */}
        <div className="mb-6">
          <input
            type="email"
            placeholder="Email"
            value={email} // Bind the input to email state
            onChange={(e) => setEmail(e.target.value)} // Update email state on input change
            className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500 transition-colors"
          />
        </div>
        
        {/* Generate Code Button */}
        <button
          onClick={handleGenerateCode} // Handle button click
          className="w-full bg-black text-white p-4 rounded-lg font-semibold hover:bg-gray-800 transition-all duration-200 ease-in-out"
        >
          Generate Code
        </button>


      </div>
    </div>
  );
};

export default Login;