import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
// import { useTranslation } from "react-i18next";



const HomeImages = () => {
  // const { t, i18n } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng); // Change language dynamically
  // };

  const { t } = useTranslation();


  return (
    <div className="p-4 space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:mb-10">
        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1730880395/IMG_8449_hopqyk.webp"
            alt="Fash Look 1"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>

        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1733735163/doyin_ouys1e.webp"
            alt="Fash Look 2"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>

      <div className="text-center py-10 lg:py-20">
      <a
        href="/collection"
        className="text-black tracking-widest text-lg lg:text-2xl"
      >
        {t('lookbook.explore_text')}
      </a>
    </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:mb-10">
        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1730880394/IMG_8720_zkkdzl.webp"
            alt="Fash Look 3"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>

        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1730880395/IMG_8402_oawvzr.webp"
            alt="Fash Look 4"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>

      {/* <div>
      <h1>{t("welcome")}</h1>
      <p>{t("description")}</p>
      <p>{t("collection")}</p>
      <button onClick={() => changeLanguage("en")}>English</button>
      <button onClick={() => changeLanguage("fr")}>Français</button>
    </div> */}
    </div>
  );
};

export default HomeImages;
