import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import axios from 'axios'; // Import axios for making HTTP requests

const OtpLogin = () => {
  const [otp, setOtp] = useState(''); // State to hold OTP
  const [timer, setTimer] = useState(120); // Timer set for 2 minutes (120 seconds)
  const [canResend, setCanResend] = useState(false); // Control the resend button
  const navigate = useNavigate(); // Initialize useNavigate
  
  // Retrieve email from localStorage
  const email = localStorage.getItem("adminEmail"); // Ensure this is stored in Login component

  useEffect(() => {
    // Countdown timer logic
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setCanResend(true); // Enable resend button when timer reaches 0
    }
    return () => clearInterval(interval); // Cleanup timer
  }, [timer]);

  const handleSignIn = async () => {
    try {
      const response = await axios.post('https://fash-backend-59z5.onrender.com/admin/sign-in', {
        email,
        otp,
      });
      
      if (response.data.status) { // Check for status instead of success
        localStorage.setItem("adminToken", response.data.token); // Store token
        navigate('/admin/dashboard'); // Navigate to admin dashboard on success
      }
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  const handleResendOtp = async () => {
    // Logic to resend OTP
    setCanResend(false); // Disable the button immediately after clicking
    setTimer(120); // Reset the timer for another 2 minutes (120 seconds)

    try {
      await axios.post('https://fash-backend-59z5.onrender.com/au/refresh', { email });
    } catch (error) {
      console.error('Error resending OTP:', error);
    }
  };

  return (

<div className="flex justify-center items-center bg-gray-50">
      <div className="rounded-xl shadow-xl w-full">
        {/* Admin Sign In Header */}
        <h2 className="text-3xl font-bold text-center mb-6">Admin Sign in</h2>
        
        {/* Description */}
        <p className="text-gray-500 text-center mb-8">
          Log in to the Fash dashboard with your credentials to manage operations.
        </p>
        
        {/* Input Field */}
        <div className="mb-6">
          <input
            type="text" // Use text to avoid up/down buttons
            placeholder="Enter OTP"
            value={otp} // Bind the input to otp state
            onChange={(e) => setOtp(e.target.value)} // Update otp state on input change
            className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500 transition-colors"
          />
        </div>

        {/* Timer and Resend OTP */}
        <div className="flex justify-between items-center mb-6">
          {timer > 0 ? (
            <span className="text-gray-500">Resend OTP in {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')}s</span>
          ) : (
            <button
              onClick={handleResendOtp}
              disabled={!canResend} // Disable button until timer ends
              className={`text-blue-500 font-semibold hover:underline ${!canResend && 'opacity-50'}`}
            >
              Resend OTP
            </button>
          )}
        </div>

        {/* Sign In Button */}
        <button
          onClick={handleSignIn} // Handle sign in button click
          className="w-full bg-black text-white p-4 rounded-lg font-semibold hover:bg-gray-800 transition-all duration-200 ease-in-out"
        >
          Sign In
        </button>
      </div>
    </div>
  );
};

export default OtpLogin;
