import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Success = () => {
    let [isLoading, setIsLoading] = useState(true);
    let navigate = useNavigate();
    let location = useLocation();
    let queryParams = new URLSearchParams(location.search);
    let reference = queryParams.get('reference');

    useEffect(() => {
        let triggerCallbackAndVerify = async () => {
            try {
                // Step 1: Make the callback to update the order status
                let callbackResponse = await fetch('https://fash-backend-1.onrender.com/order/au/rf', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ reference }),
                });

                if (!callbackResponse.ok) {
                    throw new Error('Failed to update records');
                }

                console.log('Callback update successful');

                console.log(callbackResponse);

                // // Step 2: Verify the payment after callback
                // const verifyResponse = await fetch('https://fash-backend-1.onrender.com/order/au/verify', {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify({ reference }),
                // });

                // const verifyData = await verifyResponse.json();
                // console.log('Payment verification response:', verifyData);

                // if (!verifyResponse.ok || verifyData.orderStatus !== "Successful") {
                //     throw new Error('Payment verification failed');
                // }

                console.log('Order status updated successfully');
                setIsLoading(false); // Loading is complete, show success page
            } catch (error) {
                console.error('Error in payment processing:', error);
                setIsLoading(false); // Still stop loading to render an error message if needed
            }
        };

        // if (reference) {
        //     triggerCallbackAndVerify();
        // }
        triggerCallbackAndVerify();
    }, [reference]);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <p className="text-lg text-gray-600">Processing your payment...</p>
            </div>
        );
    }

    return (
        <div className="flex flex-col justify-center items-center h-screen bg-gray-50">
            <div className="bg-white shadow-md rounded-lg p-8 w-full max-w-md">
                <div className="flex justify-center items-center mb-6">
                    <div className="bg-green-200 p-2 rounded-full">
                        <svg className="h-8 w-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                </div>
                <h1 className="text-2xl font-bold text-center mb-4">PAYMENT SUCCESSFUL</h1>
                <p className="text-center mb-6 text-gray-600">
                    Thank you! Your order has been processed successfully. You will receive a confirmation email with the order details.
                </p>
                <div className="space-y-2 text-sm text-gray-700">
                    <div className="flex justify-between">
                        <span>Reference</span>
                        <span>{reference}</span>
                    </div>
                </div>
                <button
                    className="mt-8 w-full bg-black text-white py-2 hover:bg-gray-800 transition duration-300"
                    onClick={() => navigate('/')}
                >
                    Continue Shopping
                </button>
            </div>
        </div>
    );
};

export default Success;
