import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

const SearchResults = () => {
  const location = useLocation();
  const searchResults = location.state?.searchResults || []; // Expecting an array of product items

  const [hoveredProductId, setHoveredProductId] = useState(null);

  const getProductImageUrl = (product) => {
    // Return the default image URL
    return product.default_Url || 'path/to/default-image.jpg';
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Search Results</h1>
      {searchResults.length > 0 ? (
        <div className="flex flex-nowrap space-x-4 overflow-x-auto md:grid md:grid-cols-2 lg:grid-cols-4 md:gap-4">
          {searchResults.map(product => (
            <Link
              key={product._id}
              to={`/products/${product._id}`}
              className="min-w-[75%] md:min-w-0 md:w-auto p-4 rounded-lg"
              onMouseEnter={() => setHoveredProductId(product._id)}
              onMouseLeave={() => setHoveredProductId(null)}
            >
              <img
                src={hoveredProductId === product._id ? product.hover_Url : getProductImageUrl(product)}
                alt={product.name}
                loading="lazy"
                className="w-full h-96 object-cover mb-4"
              />
              <h2 className="text-md font-bold">{product.name}</h2>
              <p className="text-xs mt-2">${product.price}</p>
              <div className="mt-2 flex space-x-2">
                {product.color.map((color, index) => (
                  <button key={index} className="w-4 h-4" style={{ backgroundColor: color }} />
                ))}
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <p>No results found.</p>
      )}
    </div>
  );
};

export default SearchResults;


