import React from "react";
import { sizeGuideData } from "./sizeGuideData";
import { useTranslation } from "react-i18next";

const SizeGuide = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-lg md:text-2xl lg:text-3xl text-center mb-6 md:mb-10 tracking-widest uppercase">
        {t("size.title")}
      </h1>
      <p className="text-gray-600 mb-4 md:mb-6 text-center text-sm md:text-base">
        {t("size.description")}
      </p>
      {sizeGuideData.map((category, index) => (
        <div key={index} className="mb-8">
          <h2 className="text-xl md:text-2xl font-semibold mb-3 md:mb-4">
            {t(category.category)}
          </h2>
          <div className="overflow-x-auto">
            <table className="w-full border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="py-2 px-2 md:px-4 border-b border-gray-300 text-left text-sm md:text-base">
                    {t("size.standard_size")}
                  </th>
                  <th className="py-2 px-2 md:px-4 border-b border-gray-300 text-left text-sm md:text-base">
                    US  {t("size.size_name")}
                  </th>
                  <th className="py-2 px-2 md:px-4 border-b border-gray-300 text-left text-sm md:text-base">
                    EU  {t("size.size_name")}
                  </th>
                  {category.sizes[0].chest && (
                    <th className="py-2 px-2 md:px-4 border-b border-gray-300 text-left text-sm md:text-base">
                      {t("size.chest")}
                    </th>
                  )}
                  {category.sizes[0].waist && (
                    <th className="py-2 px-2 md:px-4 border-b border-gray-300 text-left text-sm md:text-base">
                      {t("size.waist")}
                    </th>
                  )}
                  {category.sizes[0].length && (
                    <th className="py-2 px-2 md:px-4 border-b border-gray-300 text-left text-sm md:text-base">
                      {t("size.lenght")}
                    </th>
                  )}
                  {category.sizes[0].footLength && (
                    <th className="py-2 px-2 md:px-4 border-b border-gray-300 text-left text-sm md:text-base">
                      {t("size.foot")}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {category.sizes.map((size, idx) => (
                  <tr key={idx} className="border-b border-gray-200">
                    <td className="py-2 px-2 md:px-4 text-sm">{size.size}</td>
                    <td className="py-2 px-2 md:px-4 text-sm">{size.usSize}</td>
                    <td className="py-2 px-2 md:px-4 text-sm">{size.euSize}</td>
                    {size.chest && <td className="py-2 px-2 md:px-4 text-sm">{size.chest}</td>}
                    {size.waist && <td className="py-2 px-2 md:px-4 text-sm">{size.waist}</td>}
                    {size.length && <td className="py-2 px-2 md:px-4 text-sm">{size.length}</td>}
                    {size.footLength && <td className="py-2 px-2 md:px-4 text-sm">{size.footLength}</td>}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SizeGuide;
