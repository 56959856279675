import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { HiExternalLink } from "react-icons/hi";
import { Link } from 'react-router-dom';
import axios from 'axios';

// Register the necessary components, including the Filler plugin
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const TotalOrders = () => {
  const [totalOrders, setTotalOrders] = useState(0);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Orders',
        data: [],
        borderColor: '#4B5563',
        backgroundColor: 'rgba(75, 85, 99, 0.5)',
        fill: true,
        tension: 0.3,
      },
    ],
  });

  useEffect(() => {
    // Fetch data from the API
    axios.get('https://fash-backend-59z5.onrender.com/rev')
      .then(response => {
        const data = response.data.data;

        // Extract months and total orders for the chart
        const months = data.map(item => item.month);
        const orders = data.map(item => item.totalOrder);

        // Calculate the total number of orders
        const totalOrderSum = orders.reduce((acc, curr) => acc + curr, 0);

        // Update the state with the fetched data
        setChartData({
          labels: months,
          datasets: [
            {
              label: 'Orders',
              data: orders,
              borderColor: '#4B5563',
              backgroundColor: 'rgba(75, 85, 99, 0.5)',
              fill: true,
              tension: 0.3,
            },
          ],
        });

        setTotalOrders(totalOrderSum);
      })
      .catch(error => {
        console.error("Error fetching total orders data:", error);
      });
  }, []);

  return (
    <div className="col-span-2 bg-white rounded-lg shadow-md p-6 relative">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-bold">Total Orders</h2>
      </div>
      <p className="text-6xl font-bold mt-4">{totalOrders}</p>
      <Link to="/admin/orders" className="absolute top-4 right-4 text-black">
        <HiExternalLink size={20} />
      </Link>
      <div className="mt-4">
        <Line data={chartData} />
      </div>
    </div>
  );
};

export default TotalOrders;
