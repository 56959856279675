import React from "react";
import { useTranslation } from "react-i18next";

const TermsOfService = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-lg lg:text-3xl text-center mb-10 tracking-widest uppercase">{t("terms.title")}</h1>

      <p className="mb-4">{t("terms.intro")}</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t("terms.eligibility.title")}</h2>
      <p className="mb-4">{t("terms.eligibility.content")}</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t("terms.account.title")}</h2>
      <p className="mb-4">{t("terms.account.content")}</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t("terms.orders.title")}</h2>
      <p className="mb-4">{t("terms.orders.content")}</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t("terms.returns.title")}</h2>
      <p className="mb-4">{t("terms.returns.content")}</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t("terms.ip.title")}</h2>
      <p className="mb-4">{t("terms.ip.content")}</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t("terms.liability.title")}</h2>
      <p className="mb-4">{t("terms.liability.content")}</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t("terms.indemnification.title")}</h2>
      <p className="mb-4">{t("terms.indemnification.content")}</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t("terms.law.title")}</h2>
      <p className="mb-4">{t("terms.law.content")}</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t("terms.changes.title")}</h2>
      <p className="mb-4">{t("terms.changes.content")}</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t("terms.contact.title")}</h2>
      <p className="mb-4">{t("terms.contact.content")}</p>
      <p>{t("terms.contact.name")}</p>
      <p>{t("terms.contact.location")}</p>
    </div>
  );
};

export default TermsOfService;
