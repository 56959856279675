import React from 'react';
import { useNavigate } from 'react-router-dom';

const FailedPayment = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-50">
      <div className="bg-white shadow-md rounded-lg p-8 w-full max-w-md">
        <div className="flex justify-center items-center mb-6">
          <div className="bg-red-200 p-2 rounded-full">
            <svg className="h-8 w-8 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
        <h1 className="text-2xl font-bold text-center mb-4">PAYMENT FAILED</h1>
        <p className="text-center mb-6 text-gray-600">
          Sorry, something went wrong during checkout.
        </p>
        <div className="flex justify-between space-x-4">
          <button
            className="w-full bg-black text-white py-2 hover:bg-gray-800 transition duration-300"
            onClick={() => navigate('/checkout')}
          >
            Try Again
          </button>
          <button
            className="w-full bg-gray-100 text-gray-600 py-2 hover:bg-gray-200 transition duration-300"
            onClick={() => navigate('/bag')}
          >
            Back to Bag
          </button>
        </div>
      </div>
    </div>
  );
};

export default FailedPayment;
