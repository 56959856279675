import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { HiExternalLink } from "react-icons/hi";
import { Link } from 'react-router-dom';
import axios from 'axios';

const Revenue = () => {
  const [revenueChartData, setRevenueChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Revenue',
        data: [],
        backgroundColor: '#6B7280',
      },
    ],
  });

  const [totalRevenue, setTotalRevenue] = useState(0);

  useEffect(() => {
    // Fetch revenue data from the API
    axios.get('https://fash-backend-59z5.onrender.com/rev')
      .then(response => {
        const revenueData = response.data.data;

        // Extract month names and corresponding revenue values
        const months = revenueData.map(item => item.month);
        const revenues = revenueData.map(item => item.revenue);
        
        // Calculate the total revenue for the latest month
        const latestRevenue = revenues[revenues.length - 1];  // Gets the revenue of the last month in the data

        // Update the chart data
        setRevenueChartData({
          labels: months,
          datasets: [
            {
              label: 'Revenue',
              data: revenues,
              backgroundColor: '#6B7280',
            },
          ],
        });

        // Set the total revenue value
        setTotalRevenue(latestRevenue);
      })
      .catch(error => {
        console.error("Error fetching revenue data:", error);
      });
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-md p-6 col-span-1 relative">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-bold">Revenue</h2>      </div>
      <p className="text-4xl font-bold mt-4">${(totalRevenue / 1000).toFixed(1)}K</p>
      <Link to="/admin/orders" className="absolute top-4 right-4 text-black">
        <HiExternalLink size={20} />
      </Link>
      <div className="mt-4">
        <Bar data={revenueChartData} />
      </div>
    </div>
  );
};

export default Revenue;
