import React, { useRef, useState } from 'react';
import { FaVolumeMute, FaVolumeUp, FaPause, FaPlay } from 'react-icons/fa';

const HomeHero = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div 
      className="relative w-full h-[500px] sm:h-screen flex items-center justify-center overflow-hidden bg-cover bg-center"
      style={{ backgroundImage: 'url(https://example.com/path-to-your-image.jpg)' }} // Replace with your image URL
    >
      {/* Background Video */}
      <div className="absolute inset-0">
        <video
          ref={videoRef}
          src="https://res.cloudinary.com/deofmlywm/video/upload/v1733734133/fash_video_1_u8icyc.mp4" // Replace with your video URL
          autoPlay
          loop
          muted={isMuted}
          playsInline
          loading="lazy" // This is where we add lazy loading
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </div>

      {/* Overlay for both mobile and desktop */}
      <div className="absolute inset-0 bg-black bg-opacity-50 sm:bg-opacity-40 lg:bg-opacity-30" />

      {/* Text Content */}
      <div className="relative z-10 text-white text-center px-4 md:px-6 tracking-widest top-32">
        {/* <h1 className="text-lg sm:text-xl md:text-2xl tracking-widest mb-2 sm:mb-4">FALL / WINTER 2024</h1>
        <p className="text-xl sm:text-2xl md:text-3xl tracking-widest mb-4 sm:mb-8">DISCOVER AMAZING MEN AND WOMEN WEARS</p> */}
        
        {/* Buttons can be re-enabled as needed */}
        {/* <div className="flex gap-4 justify-center mt-4">
          <a href="/men" className="bg-white text-black w-20 sm:w-24 py-2 sm:py-3 text-xs sm:text-sm text-center hover:bg-gray-200 transition duration-300">MEN</a>
          <a href="/women" className="bg-white text-black w-20 sm:w-24 py-2 sm:py-3 text-xs sm:text-sm text-center hover:bg-gray-200 transition duration-300">WOMEN</a>
        </div> */}
      </div>

      {/* Controls */}
      <button
        onClick={toggleMute}
        className="absolute bottom-4 left-4 text-white p-1 sm:p-2 rounded-full shadow hover:bg-gray-200 transition duration-300"
      >
        {isMuted ? <FaVolumeMute size={14} /> : <FaVolumeUp size={14} />}
      </button>
      <button
        onClick={togglePlayPause}
        className="absolute bottom-4 right-4 text-white p-1 sm:p-2 rounded-full shadow hover:bg-gray-200 transition duration-300"
      >
        {isPlaying ? <FaPause size={14} /> : <FaPlay size={14} />}
      </button>
    </div>
  );
};

export default HomeHero;
