import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Orders from "./pages/Orders";
import Login from "./pages/Login";
import Users from "./pages/Users";
import AddProducts from "./pages/AddProducts";
import EditProducts from "./pages/EditProducts";
import OtpLogin from "./pages/OtpLogin";
import Products from "./pages/Products";
import Collection from "./pages/Collection";
import AddCollection from "./pages/AddCollection";
import EditCollection from "./pages/EditCollection";
import AddInventory from "./pages/AddInventory";

const AdminRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("adminToken"));

  // Listen for authentication changes (e.g., after logout)
  useEffect(() => {
    const checkAuthStatus = () => {
      const token = localStorage.getItem("adminToken");
      setIsAuthenticated(!!token); // Update state when token changes
    };

    // Add a storage event listener to detect changes from other tabs
    window.addEventListener("storage", checkAuthStatus);

    return () => {
      window.removeEventListener("storage", checkAuthStatus);
    };
  }, []);

  return (
    <Routes>
      {!isAuthenticated ? (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/otp-login" element={<OtpLogin />} />
          <Route path="*" element={<Navigate to="/admin/login" />} />
        </>
      ) : (
        <>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/product" element={<Products />} />
          <Route path="/addproducts" element={<AddProducts />} />
          <Route path="/editproduct/:id" element={<EditProducts />} />
          <Route path="/addinventory/:id" element={<AddInventory />} />
          <Route path="/collection" element={<Collection />} />
          <Route path="/addcollection" element={<AddCollection />} />
          <Route path="/editcollection/:id" element={<EditCollection />} />
          <Route path="/users" element={<Users />} />
          <Route path="*" element={<Navigate to="/admin/dashboard" />} />
        </>
      )}
    </Routes>
  );
};

export default AdminRoutes;



// import { Routes, Route, Navigate } from "react-router-dom";
// import Dashboard from "./pages/Dashboard";
// import Orders from "./pages/Orders";
// import Login from "./pages/Login";
// import Users from "./pages/Users";
// import AddProducts from "./pages/AddProducts";
// import EditProducts from "./pages/EditProducts";
// import Inventory from "./pages/Inventory";

// const AdminRoutes = () => {
//   // Temporarily set this to true to bypass login for development
//   const isAuthenticated = true; // Change this to true to access all routes without login

//   return (
//     <Routes>
//       {!isAuthenticated ? (
//         <>
//           <Route path="/login" element={<Login />} />
//           <Route path="*" element={<Navigate to="/admin/login" />} />
//         </>
//       ) : (
//         <>
//           <Route path="/dashboard" element={<Dashboard />} />
//           <Route path="/addproducts" element={<AddProducts />} />
//           <Route path="/editproducts" element={<EditProducts />} />
//           <Route path="/inventory" element={<Inventory />} />
//           <Route path="/orders" element={<Orders />} />
//           <Route path="/users" element={<Users />} />
//           <Route path="*" element={<Navigate to="/admin/dashboard" />} />
//         </>
//       )}
//     </Routes>
//   );
// };

// export default AdminRoutes;
