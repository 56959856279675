import React from "react";
import { useTranslation } from "react-i18next";

const ReturnsAndRefunds = () => {
  const { t } = useTranslation();
  const returnsRefundsData = t("returnsRefunds.data", { returnObjects: true });

  if (!Array.isArray(returnsRefundsData)) {
    console.error("returnsRefundsData is not an array:", returnsRefundsData);
    return <p>Error: Unable to load returns and refunds data.</p>;
  }

  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <h1 className="text-lg lg:text-3xl text-center mb-10 tracking-widest uppercase">
        {t("returnsRefunds.title")}
      </h1>
      <p className="text-gray-600 mb-6">{t("returnsRefunds.intro")}</p>
      <div>
        {returnsRefundsData.map((item, index) => (
          <div key={index} className="mb-6">
            <h2 className="text-xl font-semibold mb-2">{item.title}</h2>
            <p className="text-gray-600">{item.details}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReturnsAndRefunds;
