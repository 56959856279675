import { useState, useEffect } from "react";
import axios from "axios";

const Users = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.get("/api/admin/users").then((response) => {
      setUsers(response.data);
    });
  }, []);

  const banUser = (userId) => {
    axios.put(`/api/admin/users/${userId}/ban`).then(() => {
      setUsers(users.map((user) =>
        user.id === userId ? { ...user, banned: true } : user
      ));
    });
  };

  return (
    <div>
      <h1>Manage Users</h1>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            {user.name} - {user.banned ? "Banned" : "Active"}
            <button onClick={() => banUser(user.id)}>Ban User</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Users;
