import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
const Spinner = () => (
  <div className="flex justify-center items-center min-h-[400px]">
    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-black border-opacity-75"></div>
  </div>
);

const CollectionDetails = () => {
  
  const { id } = useParams(); // Get the collection ID from the URL
  const [collection, setCollection] = useState(null);

  useEffect(() => {
    const fetchCollection = async () => {
      try {
        const response = await fetch(`https://fash-backend-1.onrender.com/item/collection/${id}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("Fetched collection data:", data); // Log the fetched data
        setCollection(data.message.collection); // Access the collection from the message property
      } catch (error) {
        console.error("Failed to fetch collection:", error);
      }
    };
  
    fetchCollection();
  }, [id]);

  // if (!collection) {
  //   return <div>Loading...</div>; // Optional loading state
  // }

  if (!collection) return <Spinner />; // Use Spinner component

  return (
    <div className="container mx-auto px-4 lg:px-0 mt-10 overflow-hidden">
      <h1 className="text-xl lg:text-3xl tracking-widest mb-4 text-center">{collection.name}</h1>
      <p className="text-justify text-md lg:text-lg ">{collection.des}</p>

     <div className="w-full relative">
  {/* Container for the image */}
  <img 
    src={collection.img_Url} 
    alt="Description of the collection" // Provide a descriptive alt text
    className="max-w-full h-[calc(100vh-50px)] object-cover mx-auto md:h-auto py-5" // Adjusting height and padding
  />
</div>


      {/* <div className="container mx-auto  mb-10"> 
        <p className="text-justify text-md lg:text-lg mb-8">{collection.des}</p>
      </div> */}
    </div>
  );
};

export default CollectionDetails;
