// src/components/CorporateResponsibility.js
import React from "react";
import { useTranslation } from "react-i18next";


const CorporateResponsibility = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-lg lg:text-3xl text-center mb-10 tracking-widest uppercase">
      {t('corporate_responsibility.title')}
      </h1>

      <p className="mb-4">
        {t('corporate_responsibility.intro')}
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">  {t('corporate_responsibility.environmental_sustainability.title')}  </h2>
      <p className="mb-4">
        {t('corporate_responsibility.environmental_sustainability.description')}
      </p>

      <ul className="list-disc list-inside mb-4">
          {t('corporate_responsibility.environmental_sustainability.points', { returnObjects: true }).map((point, index) => (
            <li className="mb-2" key={index}>{point}</li>
          ))}
        </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t('corporate_responsibility.ethical_sourcing.title')}</h2>
      <p className="mb-4">
      {t('corporate_responsibility.ethical_sourcing.description')}
      </p>

      <ul className="list-disc list-inside mb-4">
          {t('corporate_responsibility.ethical_sourcing.points', { returnObjects: true }).map((point, index) => (
            <li className="mb-2" key={index}>{point}</li>
          ))}
        </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4"> {t('corporate_responsibility.community_engagement.title')}</h2>
      <p className="mb-4">
      {t('corporate_responsibility.community_engagement.description')}
      </p>
   


      <ul className="list-disc list-inside mb-4">
          {t('corporate_responsibility.community_engagement.points', { returnObjects: true }).map((point, index) => (
            <li className="mb-2" key={index}>{point}</li>
          ))}
        </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">  {t('corporate_responsibility.transparency.title')}</h2>
      <p className="mb-4">
      {t('corporate_responsibility.transparency.description')}
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">  {t('corporate_responsibility.looking_ahead.title')}</h2>
      <p className="mb-4">
      {t('corporate_responsibility.looking_ahead.description')}
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">   {t('corporate_responsibility.contact.title')}</h2>
      <p className="mb-4">
      {t('corporate_responsibility.contact.description')}
      </p>
      <p className="font-semibold">Fash Attire</p>
      <p>
        <a href="mailto:info.fashattire@gmail.com" className="text-blue-600 underline">
        {t('corporate_responsibility.contact.email')}
        </a>
      </p>
    </div>
  );
};

export default CorporateResponsibility;
