import React from "react";
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-lg lg:text-3xl text-center mb-10 tracking-widest uppercase">{t('privacy_policy.title')}</h1>
      
      <p className="text-gray-600 mb-4">
        {t('privacy_policy.intro')}
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacy_policy.information_we_collect')}</h2>
      <p className="mb-4">
        {t('privacy_policy.device_information')}
      </p>
      <p className="mb-4">
        {t('privacy_policy.collection_methods')}
      </p>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">{t('privacy_policy.cookies')}</li>
        <li className="mb-2">{t('privacy_policy.log_files')}</li>
        <li>{t('privacy_policy.web_beacons')}</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacy_policy.how_we_use_your_info')}</h2>
      <p className="mb-4">
        {t('privacy_policy.usage_purpose')}
      </p>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">{t('privacy_policy.transaction_management')}</li>
        <li className="mb-2">{t('privacy_policy.customer_support')}</li>
        <li className="mb-2">{t('privacy_policy.fraud_screening')}</li>
        <li className="mb-2">{t('privacy_policy.marketing')}</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacy_policy.sharing_your_info')}</h2>
      <p className="mb-4">
        {t('privacy_policy.sharing_info_description')}
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacy_policy.behavioral_advertising')}</h2>
      <p className="mb-4">
        {t('privacy_policy.behavioral_ad_description')}
        <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work" className="text-blue-600 underline">
          {t('privacy_policy.targeted_ad_link')}
        </a>
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacy_policy.your_rights')}</h2>
      <p className="mb-4">
        {t('privacy_policy.rights_info')}
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacy_policy.changes')}</h2>
      <p className="mb-4">
        {t('privacy_policy.changes_description')}
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacy_policy.contact_us')}</h2>
      <p>{t('privacy_policy.contact_description')}</p>
      <br />
      <p>{t('privacy_policy.contact_company')}</p>
      <p>{t('privacy_policy.contact_location')}</p>
    </div>
  );
};

export default PrivacyPolicy;
