import React, { useState } from "react";
import axios from "axios";
import { FaInstagram, FaTiktok } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';


const Footer = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const API_KEY = "OjgAPtCOXrDmx1yFz6sieg";
    const FORM_ID = "7330678"; 

    const subscriberData = {
      api_key: API_KEY,
      email: email,
    };

    try {
      const response = await axios.post(
        `https://api.convertkit.com/v3/forms/${FORM_ID}/subscribe`,
        subscriberData
      );

      if (response.data.subscription) {
        setMessage("Successfully subscribed! Please check your email.");
        setEmail(""); 
      } else {
        setMessage("Subscription failed. Please try again.");
      }
    } catch (error) {
      console.error("Error subscribing:", error);
      setMessage("An error occurred. Please try again later.");
    }

    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  const { t, i18n } = useTranslation();

  const languages = [
    { code: "en", name: "English" },
    { code: "fr", name: "Français" },
    { code: "es", name: "Espagnol" },
  ]
  return (
    <footer className="bg-white text-black py-10 border-t border-gray-300 tracking-widest">
      <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-4 lg:grid-cols-5">
         
        



            <div className="col-span-2 sm:col-span-1">
            <h3 className="font-semibold uppercase text-gray-800 text-md">{t('newsletter.title')}</h3>
            <p className="mt-2 text-gray-600 text-xs">{t('newsletter.description')}</p>
            <form onSubmit={handleSubmit} className="mt-4 flex flex-row space-x-2 sm:flex-col sm:space-x-0 sm:space-y-2">
              <input
                type="email"
                placeholder={t('newsletter.input_placeholder')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 focus:ring-black focus:border-black text-xs"
                required
              />
              <button
                type="submit"
                className="w-full bg-black text-white px-4 py-2 text-xs"
              >
            {t('newsletter.button_text')}
              </button>
            </form>
            {message && <p className="text-xs text-gray-600 mt-2">{message}</p>}
          </div>
            {message && (
              <div className={`mt-2 text-sm ${message.includes('fail') ? 'text-red-500' : 'text-green-500'}`}>
                {message}
              </div>
            )}
     
        
<div className="mt-5 lg:mt-0 lg:ml-5">
<h5 className="font-semibold uppercase text-gray-800 text-sm">{t('support.title')}</h5>

  <ul className="mt-4 space-y-2 text-xs text-gray-600">
    <li><Link to="/contact" className="hover:text-black">{t('support.contact_us')}</Link></li>
    <li><Link to="/faq" className="hover:text-black">{t('support.faqs')}</Link></li>
    <li><Link to="/ReturnsAndRefunds" className="hover:text-black">{t('support.returns_refunds')}</Link></li>
    <li><Link to="/DeliveryInformation" className="hover:text-black">{t('support.delivery_info')}</Link></li>
    <li><Link to="/InternationalCharges" className="hover:text-black">{t('support.international_charges')}</Link></li>
    <li><Link to="/SizeGuide" className="hover:text-black">{t('support.size_guide')}</Link></li>
  </ul>
</div>

<div className="mt-5 lg:mt-0">
<h5 className="font-semibold uppercase text-gray-800 text-sm">{t('legal.title')}</h5>


  <ul className="mt-4 space-y-2 text-xs text-gray-600">
    <li><Link to="/PrivacyPolicy" className="hover:text-black">{t('legal.privacy_policy')}</Link></li>
    <li><Link to="/TermsOfService" className="hover:text-black">{t('legal.terms_of_service')}</Link></li>
  </ul>
</div>

<div className="mt-5 lg:mt-0">
<h5 className="font-semibold uppercase text-gray-800 text-sm">{t('about_fash.title')}</h5>

  <ul className="mt-4 space-y-2 text-xs text-gray-600">
    <li><Link to="/AboutUs" className="hover:text-black">{t('about_fash.about_us')}</Link></li>
    <li><Link to="/CorporateResponsibility" className="hover:text-black">{t('about_fash.corporate_responsibility')}</Link></li>
    <li>{t('about_fash.store_locator')}</li>
  </ul>
</div>

          <div className="mt-5 lg:mt-0">
  <h3 className="font-semibold uppercase text-gray-800 text-sm">Language</h3>
  {languages.map(({ code, name }) => (
    <p
      key={code}
      onClick={() => i18n.changeLanguage(code)}
      className={`mt-2 text-xs cursor-pointer ${
        i18n.language === code ? "text-gray-800 font-semibold" : "text-gray-600"
      }`}
    >
      {name}
    </p>
  ))}
</div>

        </div>

        <div className="mt-8 pt-4 flex flex-col items-center justify-center sm:flex-row sm:justify-between">
          <p className="text-gray-600 text-xs">© Fash, 2024</p>
          <div className="flex space-x-4 mt-2 sm:mt-0">
  <a 
    href="https://www.instagram.com/fash.attire/" 
    className="text-gray-600 hover:text-black" 
    target="_blank" 
    rel="noopener noreferrer" 
    aria-label="Visit our Instagram page"
  >
    <FaInstagram size={15} />
  </a>
  
  <a 
    href="https://www.tiktok.com/@fashattire" 
    className="text-gray-600 hover:text-black" 
    target="_blank" 
    rel="noopener noreferrer" 
    aria-label="Visit our TikTok page"
  >
    <FaTiktok size={15} />
  </a>
</div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
