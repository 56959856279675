import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Spinner = () => (
  <div className="flex justify-center items-center min-h-[400px]">
    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-black border-opacity-75"></div>
  </div>
);

const MenShoes = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 4; // Number of items to show each time
  const [userPosts, setUserPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
;
  const [size, setSize] = useState('');
  const [color, setColor] = useState('');
  const [collection, setCollection] = useState('');
  const [sortBy, setSortBy] = useState('Recommended');
  const [hoveredProductId, setHoveredProductId] = useState(null); // State for hovered product
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('https://fash-backend-59z5.onrender.com/item/store');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUserPosts(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };



    const fetchCollections = async () => {
      try {
        const response = await fetch('https://fash-backend-59z5.onrender.com/item/collection');
        if (!response.ok) {
          throw new Error('Failed to fetch collections');
        }
        const data = await response.json();
        setCollections(data.map(collection => collection.name));
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
    };

    fetchPosts();
    fetchCollections();
  }, []);

  const handleLoadMore = () => {
    setCurrentIndex(prev => prev + itemsToShow);
  };

  // Filtered and sorted Men items
  const menItems = userPosts
  .filter(product => product.sex === 'Male' && product.category === 'Shoes') // Filter by Men and Trousers
  
    .filter(product => !size || product.size.includes(size))
    .filter(product => !color || product.color.includes(color))
    .filter(product => !collection || product._collection === collection)
    .sort((a, b) => {
      switch (sortBy) {
        case 'Price (Low to High)':
          return a.price - b.price;
        case 'Price (High to Low)':
          return b.price - a.price;
        case "What's New":
          return new Date(b.createdAt) - new Date(a.createdAt);
        default:
          return 0;
      }
    });

  const getProductImageUrl = (product) => {
    if (product.img_Url) {
      return product.img_Url.startsWith('http') ? product.img_Url : `${process.env.REACT_APP_BASE_URL}${product.img_Url}`;
    }
    return '/images/default-product.jpg'; // Fallback image
  };

  // Show loading message or error message
  if (loading) return <Spinner />; // Use Spinner component
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto px-1">
         <div className="mt-4 mb-4 text-sm ml-2">
         <span>{t('men.mentag')} &gt; </span>
        <span className="tracking-widest">{t('men.viewshoes')}</span>
      </div>



      
<h1 className="text-xl lg:text-3xl ml-2 tracking-widest mb-4 font-bold">{t('men.shoes')}</h1>

  
<p className="mb-8 text-sm ml-2"> {t('men.shoesdescription')}</p>
<div className="flex flex-col lg:flex-row justify-between items-center space-y-4 lg:space-y-0 mb-10">
        <div className="flex space-x-4">
       

          <select value={size} onChange={(e) => setSize(e.target.value)} className="pr-2 rounded leading-tight focus:outline-none text-sm">
            <option value="">{t('men.size')}</option>
            <option value="XS">XS</option>
            <option value="S">S</option>
            <option value="M">M</option>
            <option value="L">L</option>
            <option value="XL">XL</option>
            <option value="XXL">XXL</option>
          </select>

          <select value={color} onChange={(e) => setColor(e.target.value)} className="pr-2 rounded leading-tight focus:outline-none text-sm">
            <option value="">{t('men.color')}</option>
      <option value="Black">{t('colors.black')}</option>
      <option value="White">{t('colors.white')}</option>
      <option value="Beige">{t('colors.beige')}</option>
      <option value="Brown">{t('colors.brown')}</option>
      <option value="Cream">{t('colors.cream')}</option>
          </select>

          <select value={collection} onChange={(e) => setCollection(e.target.value)} className="pr-2 rounded leading-tight focus:outline-none text-sm">
            <option value="">{t('men.collection')}</option>
            {collections.map((col, index) => (
              <option key={index} value={col}>{col}</option>
            ))}
          </select>
        </div>

        <div className="flex space-x-4">
        <select
      value={sortBy}
      onChange={(e) => setSortBy(e.target.value)}
      className="pr-2 rounded leading-tight focus:outline-none text-sm"
    >
      <option value="Recommended">{t('men.sort_by')}: {t('men.recommended')}</option>
      <option value="What's New">{t('men.whats_new')}</option>
      <option value="Price (Low to High)">{t('men.price_low_to_high')}</option>
      <option value="Price (High to Low)">{t('men.price_high_to_low')}</option>
    </select>
        </div>
      </div>

       <div className="grid grid-cols-2 lg:grid-cols-4 gap-0 md:gap-4">

        {menItems.slice(currentIndex, currentIndex + itemsToShow).map(product => (
          <Link 
            key={product._id} 
            to={`/products/${product._id}`} 
            className="min-w-[75%] md:min-w-0 md:w-auto p-4 rounded-lg"
            onMouseEnter={() => setHoveredProductId(product._id)} // Set hovered product ID
            onMouseLeave={() => setHoveredProductId(null)} // Reset hovered product ID
          >
              <img
            src={hoveredProductId === product._id ? product.hover_Url : getProductImageUrl(product)} 
            alt={product.name}
      loading="lazy"
      className="w-full h-56 md:h-96 object-cover mb-2"
    />
                <h2 className="text-sm font-semibold uppercase">{product.name}</h2>

<p className="text-xs md:text-sm mt-2">${product.price}</p>
            <div className="mt-2 flex space-x-2">
              {product.color.map((color, index) => (
                <button key={index} className="w-4 h-4" style={{ backgroundColor: color }} />
              ))}
            </div>
          </Link>
        ))}
      </div>

      {currentIndex + itemsToShow < menItems.length && (
        <div className="text-center mt-20">
          <button
            onClick={handleLoadMore}
            className="px-7 py-4 bg-black text-white uppercase transition duration-300 hover:bg-gray-800 tracking-widest"
          >
            LOAD MORE PRODUCTS
          </button>
        </div>
      )}
    </div>
  );
};

export default MenShoes;
