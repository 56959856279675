import React, { useState } from 'react';
import { useCart } from '../../Context/CartContext';
import { useNavigate } from 'react-router-dom';
import Visa from '../../Images/Payment/visa.png'
import MasterCard from '../../Images/Payment/mastercard.png'
import { useTranslation } from 'react-i18next';


export default function Checkout() {
  const { t } = useTranslation();
  const { cart } = useCart();
  const totalPrice = cart.reduce((total, item) => total + item.price * item.quantity, 0);
  const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", 
    "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", 
    "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", 
    "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic", "Chad", 
    "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the", "Congo, Republic of the", 
    "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", 
    "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", 
    "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", 
    "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", 
    "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea, North", 
    "Korea, South", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", 
    "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", 
    "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", 
    "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", 
    "North Macedonia", "Norway", "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", 
    "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", 
    "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", 
    "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", 
    "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", 
    "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", 
    "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", 
    "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
  ];
  
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address1: '',
    address2: '',
    city: '',
    zipCode: '',
    country: '',
    state: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault(); 
  
    // Combine first name and last name into a single name field
    const fullName = `${formData.firstName} ${formData.lastName}`;
  
    let orderDetails = {
      name: fullName, // Set the name field
      amount: Math.round(totalPrice),
      phone_Number: Number(formData.phoneNumber),
      email: formData.email,
      shippingAddress: {
        street: formData.address1,
        city: formData.city,
        state: formData.state,
        postalCode: Number(formData.zipCode),
        country: formData.country,
      },
      items: cart.map((item) => ({
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        price: Math.round(item.price),
        color: item.color,
        size: item.size,
      })),
      //  callback_url: `${window.location.origin}/payment-callback?reference=`, // Adjust this
  //  callback_url: ${window.location.origin}/payment-callback?reference=, // Adjust this

  callback_url: '/success-payment', 
      failure_url: '/', 
    };
    
    try {
      const response = await fetch('https://fash-backend-59z5.onrender.com/order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderDetails),
      });
  
      const data = await response.json();
      
      if (data && data.data && data.data.authorization_url) {
        window.location.href = data.data.authorization_url;
      } else {
        console.error('Paystack link not found:', data);
        window.alert('Failed to get payment link. Please try again.');
        navigate('/failed-payment');
      }
    } catch (error) {
      console.error('Error:', error);
      window.alert('An error occurred. Please try again.');
      navigate('/failed-payment');
    }
  };
  
  // const handlePaymentCallback = async (reference) => {
  //   try {
  //     const response = await fetch('https://fash-backend-59z5.onrender.com/order/au/rf', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ reference }),
  //     });
  
  //     const data = await response.json();
  //     if (data.success) {
  //       navigate('/success-payment'); // Navigate to success page
  //     } else {
  //       window.alert('Payment verification failed. Please contact support.');
  //       navigate('/failed-payment');
  //     }
  //   } catch (error) {
  //     console.error('Error during payment verification:', error);
  //     window.alert('An error occurred while verifying payment. Please try again.');
  //     navigate('/failed-payment');
  //   }
  // };

  return (
    <div className="container mx-auto py-10 px-3">
    <h1 className="text-3xl font-bold mb-6 tracking-wide text-center">{t('checkout.checkout')}</h1>
    <form onSubmit={handleSubmit} className="grid grid-cols-1 lg:grid-cols-3 gap-8">
      <div className="lg:col-span-2 space-y-8">
        {/* Contact Information */}
        <div className="space-y-4">
          <h1 className="text-2xl font-bold mb-6 tracking-widest">{t('checkout.contact_information')}</h1>
          <p>{t('checkout.enter_email')}</p>
          <p><span className="text-red-500">*</span> {t('checkout.required_field')}</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input type="text" name="firstName" placeholder={t('checkout.first_name')} className="border p-3 rounded" onChange={handleChange} required />
            <input type="text" name="lastName" placeholder={t('checkout.last_name')} className="border p-3 rounded" onChange={handleChange} required />
            <input type="email" name="email" placeholder={t('checkout.email')} className="border p-3 rounded" onChange={handleChange} required />
            <input type="tel" name="phoneNumber" placeholder={t('checkout.phone_number')} className="border p-3 rounded" onChange={handleChange} required />
          </div>
        </div>
  
        {/* Shipping Address */}
        <div className="space-y-4 py-10">
          <h2 className="text-xl font-bold">{t('checkout.shipping_address')}</h2>
          <p><span className="text-red-500">*</span> {t('checkout.required_field')}</p>
          <div className="grid grid-cols-1 gap-4">
            <input type="text" name="address1" placeholder={t('checkout.address_1')} className="border p-3 rounded" onChange={handleChange} required />
            <input type="text" name="address2" placeholder={t('checkout.address_2')} className="border p-3 rounded" onChange={handleChange} />
            <div className="grid grid-cols-2 gap-4">
              <input type="text" name="city" placeholder={t('checkout.city')} className="border p-3 rounded" onChange={handleChange} required />
              <input type="text" name="zipCode" placeholder={t('checkout.zip_code')} className="border p-3 rounded" onChange={handleChange} required />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <select name="country" className="border p-3 rounded" onChange={handleChange} required>
                <option value="">{t('checkout.country_region')}</option>
                {countries.map((country) => (
                  <option key={country} value={country}>{country}</option>
                ))}
              </select>
              <input type="text" name="state" placeholder={t('checkout.state')} className="border p-3 rounded" onChange={handleChange} required />
            </div>
          </div>
        </div>
  
        <div className="space-y-2 py-10">
          <h2 className="text-xl font-bold">{t('checkout.delivery_options')}</h2>
          <p className="text-gray-600">{t('checkout.delivery_info')}</p>
        </div>
      </div>
  
      <div className="p-6 bg-gray-50">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-md font-bold tracking-widest">{t('checkout.order_summary')}</h1>
          <p className="font-semibold">{t('checkout.items')} ({cart.length})</p>
        </div>
  
        <div className="space-y-4">
          {cart.map((item) => (
            <div key={item.id} className="flex justify-between">
              <div className='font-semibold'>
                <h2 className="font-semibold text-md tracking-wider">{item.name}</h2>
                <p className="text-sm mt-5">{t('checkout.color')}: {item.color}</p>
                <p className="text-sm mt-2">{t('checkout.size')}: {item.size}</p>
                <p className="text-sm mt-2">{t('checkout.quantity')}: {item.quantity}</p>
              </div>
              <p className="font-bold">${(item.price * item.quantity).toFixed(2)}</p>
            </div>
          ))}
  
          <div className="flex justify-between border-t pt-4 text-sm">
            <p>{t('checkout.subtotal')}</p>
            <p>${totalPrice.toFixed(2)} USD</p>
          </div>
          <div className="flex justify-between text-sm">
            <p>{t('order_summary.delivery')}</p>
            <p className="text-green-600">{t('checkout.free')}</p>
          </div>
          <div className="flex justify-between font-bold text-md">
            <p>{t('checkout.total')}</p>
            <p>${totalPrice.toFixed(2)} USD</p>
          </div>
  
          <button type="submit" className="w-full bg-black text-white py-3 mt-4 hover:bg-gray-800 tracking-widest">
            {t('checkout.complete_purchase')}
          </button>
        </div>
        <div className="mt-8 space-y-4 text-sm">
          <div className="flex items-center space-x-2">
            <img src={MasterCard} alt="Mastercard" className="h-6 w-10" />
            <img src={Visa} alt="Visa" className="h-6 w-10" />
          </div>
          <p>{t('checkout.accepted_payment_methods')}</p>
        </div>
      </div>
    </form>
  </div>
  
  );
}
