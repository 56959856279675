import React, { useState } from 'react';

const AddCollection = () => {
  const [CollectionDetails, setCollectionDetails] = useState({
    name: '',
    des: '',
    img_Url: '',
    // img_Arr_Url: [],
  });


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  // const [additionalImages, setAdditionalImages] = useState([]);



  // Handle form changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCollectionDetails((prev) => ({ ...prev, [name]: value }));
  };

 

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    const requestBody = {
      name: CollectionDetails.name,
      img_Url: CollectionDetails.img_Url,
      // img_Arr_Url: CollectionDetails.img_Arr_Url,
      des: CollectionDetails.description,
    };

    try {
      const response = await fetch('https://fash-backend-59z5.onrender.com/item/collection', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Something went wrong. Please try again.');
      }

      const result = await response.json();
      console.log('API Response:', result);
      setSuccess('Collection added successfully!');
      setCollectionDetails({
        name: '',
        des: '',
        img_Url: '',
        // img_Arr_Url: [],
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle cover image upload
  const handleCoverImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = await uploadImage(file);
      setCoverImage(url);
      setCollectionDetails((prev) => ({ ...prev, img_Url: url }));
    }
  };

  // Handle additional images upload
  // const handleAdditionalImagesChange = async (event) => {
  //   const files = Array.from(event.target.files);
  //   const urls = await Promise.all(files.map(file => uploadImage(file)));
  //   // setAdditionalImages(urls);
  //   setCollectionDetails((prev) => ({ ...prev, img_Arr_Url: urls }));
  // };

  // Upload image to Cloudinary
  const uploadImage = async (file) => {
    const cloudName = 'deofmlywm'; // Replace with your Cloudinary cloud name
    const uploadPreset = 'my_upload_preset'; // Replace with your upload preset

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);

    const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();
    return data.secure_url; // Returns the URL of the uploaded image
  };

  return (
    <div className="p-8 max-w-screen-3xl mx-auto">
      {/* Back and Add New Collection Header */}
      <div className="flex justify-between items-center mb-6">
        <button className="text-black flex items-center" onClick={() => window.history.back()}>
          &larr; Back to Collection
        </button>
        <h1 className="text-2xl font-bold">Add New Collection</h1>
      </div>

      {/* Success or Error Message */}
      {success && <div className="text-green-500 mb-4">{success}</div>}
      {error && <div className="text-red-500 mb-4">{error}</div>}

      {/* Main Layout with Form */}
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Side - Collection Details */}
          <div>
            <div className="mb-6">
              <label className="block font-semibold">Collection Name</label>
              <input
                type="text"
                name="name"
                value={CollectionDetails.name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter Collection Name"
              />
            </div>

           

            <div className="mb-6">
              <label className="block font-semibold">Collection Description</label>
              <textarea
                name="description"
                value={CollectionDetails.description}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter Collection Description"
                rows="5"
              />
            </div>
          </div>

            {/* Right Side - Collection Images */}
            <div>
              <h3 className="text-lg font-semibold mb-4">Upload Collection Images</h3>
              <p className="mb-4">Please upload Collection images to complete your listing and showcase your item to potential buyers.</p>

              <div className="grid grid-cols-2 gap-4">
                <div className="border p-4 rounded-lg h-36 flex items-center justify-center">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleCoverImageChange}
                    className="hidden"
                    id="coverImageUpload"
                  />
                  <label htmlFor="coverImageUpload" className="cursor-pointer bg-black text-white py-2 px-4 rounded-md">
                    Select Cover Image
                  </label>
                </div>
                {/* <div className="border p-4 rounded-lg h-36 flex items-center justify-center">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleAdditionalImagesChange}
                    multiple
                    className="hidden"
                    id="additionalImagesUpload"
                  />
                  <label htmlFor="additionalImagesUpload" className="cursor-pointer bg-black text-white py-2 px-4 rounded-md">
                    Add Image URLs
                  </label>
                </div> */}
              </div>

              {coverImage && (
                <div className="mt-4">
                  <h4 className="font-semibold">Cover Image:</h4>
                  <img src={coverImage} alt="Cover" className="mt-2 max-w-full h-auto" />
                </div>
              )}

              {/* {additionalImages.length > 0 && (
                <div className="mt-4">
                  <h4 className="font-semibold">Additional Images:</h4>
                  <div className="flex flex-wrap mt-2">
                    {additionalImages.map((url, index) => (
                      <img key={index} src={url} alt={`Additional ${index + 1}`} className="w-24 h-24 object-cover mr-2 mb-2" />
                    ))}
                  </div>
                </div>
              )} */}
            </div>
          
          
        </div>

        <button
          type="submit"
          className="mt-8 w-full lg:w-1/2 bg-black text-white py-3 rounded-md font-semibold"
          disabled={loading}
        >
          {loading ? 'Adding Collection...' : 'Add Collection'}
        </button>
      </form>
    </div>
  );
};

export default AddCollection;
