import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaSignOutAlt, FaChevronLeft, FaBox } from 'react-icons/fa';
import FashLogo from '../Images/Logo/logo.svg';
import { FaSheetPlastic } from "react-icons/fa6";
import { MdSpaceDashboard } from "react-icons/md";

const AdminLayout = ({ children }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const isLoginPage = location.pathname === '/admin/login' || location.pathname === '/admin/otp-login';

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    setTimeout(() => {
      navigate('/admin/login', { replace: true });
    }, 100);
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      {isLoginPage ? (
        <div className="flex items-center justify-center w-full h-screen bg-gray-100">
          <div className="bg-white p-6 rounded-lg shadow-md w-80">
            {children}
          </div>
        </div>
      ) : (
        <>
          <aside className={`${isSidebarCollapsed ? 'w-20' : 'w-64'} bg-white p-6 flex flex-col shadow-lg transition-all duration-300 fixed top-0 left-0 h-full`}>
            <div className="flex items-center justify-between mb-8">
              <div className="flex items-center space-x-2">
                <img src={FashLogo} alt="Company Logo" className={`${isSidebarCollapsed ? 'hidden' : 'block'} h-20 w-20`} />
              </div>
              <FaChevronLeft
                className={`text-gray-600 cursor-pointer transform transition-transform ${isSidebarCollapsed ? 'rotate-180' : ''}`}
                onClick={handleCollapse}
              />
            </div>

            <div className="mb-8">
              <h2 className={`${isSidebarCollapsed ? 'hidden' : 'block'} text-black uppercase text-lg mt-10 font-semibold tracking-wide mb-10`}>Menu</h2>
              <nav className="flex flex-col space-y-10">
                <a
                  href="/admin/dashboard"
                  className={`flex items-center ${location.pathname === '/admin/dashboard' ? 'text-black' : 'text-gray-500'} font-medium hover:text-black`}
                >
                  <MdSpaceDashboard className="mr-3" /> <span className={`${isSidebarCollapsed ? 'hidden' : 'block font-semibold'}`}>Dashboard</span>
                </a>
                <a
                  href="/admin/orders"
                  className={`flex items-center ${location.pathname === '/admin/orders' ? 'text-black' : 'text-gray-500'} font-medium hover:text-black`}
                >
                  <FaSheetPlastic className="mr-3" /> <span className={`${isSidebarCollapsed ? 'hidden' : 'block font-semibold'}`}>Order</span>
                </a>
                <a
                  href="/admin/product"
                  className={`flex items-center ${location.pathname === '/admin/product' ? 'text-black' : 'text-gray-500'} font-medium hover:text-black mt-20`}
                >
                  <FaBox className="mr-3" /> <span className={`${isSidebarCollapsed ? 'hidden' : 'block font-semibold'}`}>Products</span>
                </a>
                <a
                  href="/admin/collection"
                  className={`flex items-center ${location.pathname === '/admin/collection' ? 'text-black' : 'text-gray-500'} font-medium hover:text-black mt-20`}
                >
                  <FaBox className="mr-3" /> <span className={`${isSidebarCollapsed ? 'hidden' : 'block font-semibold'}`}>Collection</span>
                </a>
              </nav>
            </div>

            <div className="mt-auto">
              <div className="mt-6 cursor-pointer flex items-center" onClick={handleLogout}>
                <FaSignOutAlt className="mr-2" />
                <span className={`${isSidebarCollapsed ? 'hidden' : 'block text-lg font-semibold'}`}>Log out</span>
              </div>
            </div>
          </aside>

          <div className={`${isSidebarCollapsed ? 'ml-20' : 'ml-64'} flex-1 flex flex-col`}>
            <header className="bg-white h-24 px-6 flex items-center justify-between shadow-md fixed top-0 left-0 right-0 ml-0 z-10" style={{ marginLeft: isSidebarCollapsed ? '5rem' : '16rem' }}>
              <div className="flex items-center space-x-4">
                <span className="text-2xl font-semibold text-black">Admin</span>
              </div>
              <div className="flex items-center space-x-4">
  <div className="flex items-center space-x-2">
    <img
      src="https://res.cloudinary.com/deofmlywm/image/upload/v1730890487/IMG_0077_swlfvf.webp"
      alt="User Avatar"
      className="w-8 h-8 rounded-full object-cover"
    />
    <span className="text-black font-medium">Kalu Kalu</span>
  </div>
</div>

            </header>

            <main className="flex-1 p-6 pt-28 ml-0">
              {children}
            </main>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminLayout;
