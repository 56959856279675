// src/components/DeliveryInformation.js
import React from "react";
// import { deliveryInformationData } from "./deliveryInformationData";
import { useTranslation } from "react-i18next";

const DeliveryInformation = () => {
  const { t } = useTranslation();

  const deliveryInformationData = t("deliveryInformation.data", { returnObjects: true });

  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <h1 className="text-lg lg:text-3xl text-center mb-10 tracking-widest uppercase">
        {t("deliveryInformation.title")}
      </h1>
      <p className="text-gray-600 mb-6">{t("deliveryInformation.intro")}</p>
      <div>
        {deliveryInformationData.map((item, index) => (
          <div key={index} className="mb-6">
            <h2 className="text-xl font-semibold mb-2">{item.title}</h2>
            <p className="text-gray-600 whitespace-pre-line">{item.details}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeliveryInformation;
